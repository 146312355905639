import styled from "styled-components";
import { fonts, landingPalette } from "../styles";

export const ContactSection = styled.section`
  padding: 7rem 80px 0 80px;

  @media (max-width: 600px) {
    padding: 7rem 32px 0 32px;
  }

  @media (max-width: 425px) {
    padding: 7rem 16px 0 16px;
  }

  .main-landing-container-row {
    justify-content: space-between;
    align-items: flex-start;
    grid-gap: 4rem;

    @media (max-width: 1070px) {
      flex-direction: column;
      align-items: center;
    }
  }

  article {
    max-width: 480px;
    position: relative;

    @media (max-width: 1070px) {
      max-width: 100%;
    }

    h1 {
      font-size: 2.3rem;
      line-height: 2.9rem;
      color: ${landingPalette.orange};
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 1.6rem;
    }

    h2 {
      font-size: 5.6rem;
      line-height: 6rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProBold};
      margin-bottom: 2.2rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: ${landingPalette.greyChevron};
      font-family: ${fonts.sofiaProRegular};
    }

    img {
      position: absolute;
      max-width: 96px;
      width: 100%;
      right: 0;

      @media (max-width: 1070px) {
        display: none;
      }
    }
  }

  article .contact-network {
    margin-top: 10rem;
    margin-bottom: 10rem;
    h5 {
      font-size: 1.8rem;
      line-height: 5rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 3.2rem;
    }

    > div {
      display: flex;
      grid-gap: 4rem;
    }

    @media (max-width: 1070px) {
      display: none;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    grid-gap: 20px;

    @media (max-width: 1070px) {
      max-width: 100%;
    }

    input,
    textarea,
    select {
      border-radius: 4px;
      border: 1px solid #d8d8d8;
      padding: 0 10px;
      font-size: 1.6rem;
      line-height: 1.9rem;
      font-family: ${fonts.sofiaProRegular};
      color: ${landingPalette.greyChevron};
    }

    input,
    select {
      height: 45px;
    }

    textarea {
      padding: 10px;
    }

    .select-input {
      display: flex;
      grid-gap: 1rem;
    }

    .form-input {
      display: flex;
      flex-direction: column;

      label {
        font-size: 1.8rem;
        line-height: 2.3rem;
        font-family: ${fonts.SofiaProMedium};
        color: ${landingPalette.blue};
        margin-bottom: 1rem;
      }
    }

    .type-user-list {
      display: flex;
      grid-gap: 2rem;
      margin-bottom: 2rem;
      span {
        cursor: pointer;
        border: solid 1px ${landingPalette.greyChevron};
        height: 44px;
        border-radius: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 3.2rem;
        font-size: 1.4rem;
        font-family: ${fonts.sofiaProRegular};
        color: ${landingPalette.greyChevron};
      }

      span.chip-selected {
        color: ${landingPalette.blue};
        border: 2px solid ${landingPalette.blue};
        font-family: ${fonts.SofiaProBold};
      }
    }

    button {
      border: none;
      outline: none;
      background-color: ${landingPalette.orange};
      border-radius: 21px;
      height: 44px;
      padding: 0 30px;
      font-family: ${fonts.SofiaProMedium};
      font-size: 1.6rem;
      color: white;
      line-height: 2rem;
      cursor: pointer;
      margin-top: 2rem;
      margin-bottom: 10rem;
    }
  }
`;
